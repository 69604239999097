var httpModule = angular.module('httpModule', []);

httpModule.factory('HttpService', function ($http) {

    var httpService = {
    		submitForm: function (options) {
                var promise = $http({
                    method: options.method,
                    url: options.url,
                    data: options.data,
                    headers: {'Content-Type': 'application/json; charset=UTF-8'},
                    timeout: 30000,
                    contentType: 'application/json'
                });

            return promise;
        }
    };

    return httpService;
});